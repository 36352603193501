require('../scss/carousel.scss');
import 'owl.carousel';

if (jQuery('.owl-carousel').length) {
	jQuery('.owl-carousel').owlCarousel({
		loop: true,
		nav: false,
		dots: true,
		items: 1,
		autoplay: true,
		autoplayTimeout: 8000,
		autoplayHoverPause: true,
	});
}